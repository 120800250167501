import * as React from "react"
import { Box, makeStyles, useMediaQuery } from "@material-ui/core"
import DigitalImprovementServices from "../digital-improvement-services/digital-improvement-services"
import HomeInfoBlockThree from "../home-info-blocks/home-info-block-three"
import ReferenceWorks from "../index-reference-works/reference-works"
import SendMessageBanner from "../send-message-banner/send-message-banner"
import SmallDigitalImprovementServices from "../digital-improvement-services/small-digital-improvement-services"
import { Container } from "@mui/material"

const useStyles = makeStyles((theme) => ({
  divDarkBg: {
    backgroundImage: `url("/images/wave_bg_5.jpg")`,
    transition: "background 5s linear",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: '60px'
  },
  paddingBox: {
    height: "190px",
    backgroundColor: "transparent"
  },
  offset: {
    marginTop: "-180px"
  }
}))

export interface PageBottomProps {
  isHome?: boolean;
}

const PageBottom = (props: PageBottomProps) => {
  const classes = useStyles()
  const small = useMediaQuery('(max-width:1000px)');
  return <Box>
    <Box className={classes.paddingBox} />
    <Box className={classes.divDarkBg}>
      <Container className={classes.offset}>
        {small ? <SmallDigitalImprovementServices/> : <DigitalImprovementServices />}
        {props?.isHome && <HomeInfoBlockThree />}
        {props?.isHome && <ReferenceWorks />}
        <SendMessageBanner isPaper={false} />
      </Container>
    </Box>
  </Box>
}

export default PageBottom
