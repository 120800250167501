import * as React from "react"
import { useState } from "react"
import { makeStyles, useMediaQuery, Zoom } from "@material-ui/core"
import ServiceCard from "./service-card"
import VizSensor from "react-visibility-sensor"

const useStyles = makeStyles((theme) => ({
  divAll: {
    backgroundColor: "transparent",
    width: "100%",
    height: "auto",
    paddingTop: 25,
    paddingBottom: 25
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1200,
    margin: "auto"
  },
  divSmallText: {
    fontSize: 14,
    color: "black",
    textAlign: "center",
    width: "60%",
    paddingBottom: 15
  },
  divAllCards: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down(1000)]: {
      flexWrap: "wrap",
    }
  },
  divServiceCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

  }
}))

const SmallDigitalImprovementServices = () => {
  const classes = useStyles()

  return <div className={classes.divAll}>
    <div className={classes.content}>
      <div className={classes.divAllCards}>
        <div className={classes.divServiceCard}>
          <ServiceCard title="Webshop bérlés" icon="/images/cloud.svg"
                       description="Valósítsd meg ötleted egyszerűen"
                       link="/webshop-berles" />
        </div>
        <div className={classes.divServiceCard}>
          <ServiceCard title="Webshop üzemeltetés" icon="/images/service.svg"
                       description="Bízd tapasztalt szakemberekre"
                       link="/webshop-uzemeltetes" />
        </div>
        <div className={classes.divServiceCard}>
          <ServiceCard title="Egyedi fejlesztések" icon="/images/bulb.svg"
                       description="Növelje hatékonyságát egyedi szoftverekkel"
                       link="/egyedi-fejlesztesek" />
        </div>
        <div className={classes.divServiceCard}>
          <ServiceCard title="Egyedi dizájn" icon="/images/pencil.svg"
                       description="Teljesen egyedi online megjelenés"
                       link="/egyedi-webshop-dizajn" />
        </div>
        <div className={classes.divServiceCard}>
          <ServiceCard title="Applikáció fejlesztés" icon="/images/phone.svg"
                       description="Hódítsa meg  az okostelefonok piacát"
                       link="/applikacio-fejlesztes" />
        </div>

        <div className={classes.divServiceCard}>
          <ServiceCard title="Hibafeltárás" icon="/images/bug.png"
                       description="Ha hibája van mi megkeressük & kijavítjuk"
                       link="/hibafeltaras" />
        </div>
      </div>
    </div>
  </div>
}

export default SmallDigitalImprovementServices
