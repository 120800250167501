import * as React from "react"
import { Link, makeStyles } from "@material-ui/core"
import { useState } from "react"
import Picture from "../picture/picture"

const useStyles = makeStyles((theme) => ({
  div: {
    height: 180,
    margin: 10,
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      transform: 'perspective(1px) scale(1.05)',
    },
  },
  img: {
    width: 310,
    height: 180,
    borderRadius: 8
  }
}));

const ReferenceWorkTile = ({link, image, colorImage, alt}) => {
  const classes = useStyles();

  const [currentImage, setCurrentImage] = useState(image)

  return <Link className={classes.div}
              onMouseEnter={() => setCurrentImage(colorImage)}
              onMouseLeave={() => setCurrentImage(image)}
               href={link}
  >
    <Picture
      alt={alt}
      src={currentImage}
      className={classes.img}
      lazy
    />
  </Link>
}

export default ReferenceWorkTile
