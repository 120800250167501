import * as React from "react"
import { useState } from "react"
import { makeStyles, Zoom } from "@material-ui/core"
import ServiceCard from "./service-card"
import VizSensor from "react-visibility-sensor"

const useStyles = makeStyles((theme) => ({
  divAll: {
    backgroundColor: "transparent",
    width: "100%",
    height: "auto",
    paddingTop: 25,
    paddingBottom: 25
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1200,
    margin: 'auto'
  },
  divSmallText: {
    fontSize: 14,
    color: "black",
    textAlign: "center",
    width: "60%",
    paddingBottom: 15
  },
  divAllCards: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down(1000)]: {
      flexWrap: "wrap",
    }
  },
  divServiceCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  }
}))

const DigitalImprovementServices = () => {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)

  return <div className={classes.divAll}>
    <div className={classes.content}>
      <VizSensor
        onChange={(isVisible) => {
          if (!checked) {
            setChecked(isVisible)
          }
        }}
      >
        <>
          {/*<h2 style={{textAlign: 'center'}}>Digitális fejlesztési szolgáltatások</h2>

          <div className={classes.divSmallText}>
            Olyan rendszereket tervezünk, fejlesztünk, integrálunk, amelyek kiemelkedő felhasználói élményt nyújtanak és
            versenyelőnyt biztosítanak valamennyi digitális csatornán.
          </div>*/}
          <div className={classes.divAllCards}>
            <Zoom in={checked}>
              <div className={classes.divServiceCard}>
                <ServiceCard title="Webshop bérlés" icon="/images/cloud.svg"
                             description="Valósítsd meg ötleted egyszerűen"
                             link="/webshop-berles" />
              </div>
            </Zoom>
            <Zoom in={checked} style={{ transitionDelay: checked ? "300ms" : "0ms" }}>
              <div className={classes.divServiceCard}>
                <ServiceCard title="Webshop üzemeltetés" icon="/images/service.svg"
                             description="Bízd tapasztalt szakemberekre"
                             link="/webshop-uzemeltetes" />
              </div>
            </Zoom>
            <Zoom in={checked} style={{ transitionDelay: checked ? "600ms" : "0ms" }}>
              <div className={classes.divServiceCard}>
                <ServiceCard title="Egyedi fejlesztések" icon="/images/bulb.svg"
                             description="Növelje hatékonyságát egyedi szoftverekkel"
                             link="/egyedi-fejlesztesek" />
              </div>
            </Zoom>
            <Zoom in={checked} style={{ transitionDelay: checked ? "900ms" : "0ms" }}>
              <div className={classes.divServiceCard}>
                <ServiceCard title="Egyedi dizájn" icon="/images/pencil.svg"
                             description="Teljesen egyedi online megjelenés"
                             link="/egyedi-webshop-dizajn" />
              </div>
            </Zoom>
            <Zoom in={checked} style={{ transitionDelay: checked ? "1200ms" : "0ms" }}>
              <div className={classes.divServiceCard}>
                <ServiceCard title="Applikáció fejlesztés" icon="/images/phone.svg"
                             description="Hódítsa meg  az okostelefonok piacát"
                             link="/applikacio-fejlesztes" />
              </div>
            </Zoom>
            <Zoom in={checked} style={{ transitionDelay: checked ? "1500ms" : "0ms" }}>
              <div className={classes.divServiceCard}>
                <ServiceCard title="Hibafeltárás" icon="/images/bug.png"
                             description="Ha hibája van mi megkeressük & kijavítjuk"
                             link="/hibafeltaras" />
              </div>
            </Zoom>
          </div>
        </>
      </VizSensor>
    </div>
  </div>
}

export default DigitalImprovementServices
