import * as React from "react"
import { useState } from "react"
import { Grid, makeStyles } from "@material-ui/core"
import ReferenceWorkTile from "./reference-work-tile"
import FadeIn from "react-fade-in"
import VizSensor from "react-visibility-sensor"
import Picture from "../picture/picture"

const useStyles = makeStyles((theme) => ({
  divAll: {
    height: "auto",
    width: "100%",
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: "transparent"
  },
  background: {
    transition: "5s linear",
    width: "100%",
    zIndex: -1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1200,
    margin: "auto"
  },
  divSmallText: {
    fontSize: 16,
    color: "#D3D3D3",
    textAlign: "center",
    width: "60%"
  },
  divLeftAndRight: {
    width: "100%",
    marginTop: 30,
  },
  divSide: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down(1000)]: {
      width: "100%"
    }
  },
  img: {
    height: 200,
    width: 475,
    marginBottom: 0,
    [theme.breakpoints.down(570)]: {
      flexWrap: "wrap",
      height: 125,
      width: 310
    }
  },
  title: {
    fontSize: "36px",
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginBottom: 20
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}))

const ReferenceWorks = () => {
  const classes = useStyles()
  const [fadeIn, setFadeIn] = useState(false)
  return <div className={classes.divAll}>
    {/*<ReferenceWorksBg/>*/}
    <div className={classes.content}>
      <VizSensor
        onChange={(isVisible) => {
          if (!fadeIn) {
            setFadeIn(isVisible)
          }
        }}
      >
        <div className={classes.title}>Partnereink</div>
      </VizSensor>
      <div className={classes.divSmallText}>
        Ismerj meg pár ügyfelet, akik szeretnek velünk dolgozni
      </div>

      <div className={classes.divLeftAndRight}>
        <FadeIn transitionDuration={2000} visible={fadeIn}>
          <Grid container spacing={0} style={{padding: '20px'}}>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/od/ref" colorImage="refs/od/ref_color" link="/office-depot"
                                 alt="Office Depot" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/pits/ref" colorImage="refs/pits/ref_color" link="/play-it-store"
                                 alt="PlayIT Store" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/nl/netlampa_zold" colorImage="refs/nl/netlampa_dontott" link="/netlampa"
                                 alt="Netlampa" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/pl/ref" colorImage="refs/pl/ref_color" link="/power-life"
                                 alt="Power Life" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/elit/ref" colorImage="refs/elit/ref_color" link="/elitsafety"
                                 alt="Elit Safety" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/eventure/eventure_green_card" colorImage="refs/eventure/eventure_card" link="/eventure"
                                 alt="eventure.hu" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/asztalra/zold" colorImage="refs/asztalra/dontott" link="/asztalra"
                                 alt="Asztalra.hu" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/playit/playit_ref_color" colorImage="refs/playit/playit_ref" link="/play-it"
                                 alt="PlayIt.hu" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/budapestcomiccon/budapestcomiccon_ref_color" colorImage="refs/budapestcomiccon/budapestcomiccon_ref" link="/budapestcomiccon"
                                 alt="Budapestcomiccon.hu" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/snowattack/snowattack_green_card" colorImage="refs/snowattack/snowattack_card" link="/snowattack"
                                 alt="Snowattack.hu" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/arcedula/arcedula_green_card" colorImage="refs/arcedula/arcedula_card" link="/arcedula"
                                 alt="Arcedula.hu" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
              <ReferenceWorkTile image="refs/festivaltravel/ft_green_card" colorImage="refs/festivaltravel/ft_card" link="/festivaltravel"
                                 alt="Festivaltravel.hu" />
            </Grid>
          </Grid>
        </FadeIn>
      </div>
    </div>
  </div>
}

const ReferenceWorksBg = () => {
  const classes = useStyles()

  return <Picture
    alt="alphaws"
    src="reference-works/bg"
    lazy
    className={classes.background}
  />
}

export default ReferenceWorks
