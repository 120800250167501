import * as React from "react"
import { Button, Link, makeStyles, Paper } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundImage: 'linear-gradient(to right, #40A166 , #349E87)',
    borderRadius: 25,
    // paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 30,
    paddingRight: 30,
    color: 'white',
    fontWeight: 'bold',
    /*transition: "all 0.2s",
    "&:hover": {
      transform: "perspective(1px) scale(1.05)"
    },*/
    textTransform: 'none'
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}))

const InterestedButton = ({ link, buttonText }) => {
  const classes = useStyles()

  return <Link href={link} underline="none" className={classes.link}>
    <Button className={classes.button}>
      {buttonText}
    </Button>
  </Link>
}

export default InterestedButton
