import * as React from "react"
import { makeStyles, Paper } from "@material-ui/core"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import InterestedButton from "./interested-button"
import { Link } from "gatsby"

const useStyles = makeStyles((theme) => ({
  paperAll: {
    width: "100%",
    borderRadius: 8,
    transition: "all 0.3s",
    "&:hover": {
      transform: "perspective(1px) scale(1.1)"
    },
    padding: '18px 8px',
    margin: 10,
    maxWidth: 170,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  divTitle: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 15,
    height: '70px',
    display: 'flex',
    alignItems: 'center',
  },
  divIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 15,
    paddingBottom: 15
  },
  divDescription: {
    fontSize: 12,
    textAlign: "center",
    color: "grey",
    paddingTop: 15,
    paddingBottom: 15,
    /*overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1, /!* number of lines to show *!/
    "-webkit-box-orient": 'vertical'*/
  },
  link: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: `none`,
    color: "black"
  }
}))

const ServiceCard = ({ title, icon, description, link }) => {
  const classes = useStyles()

  return <Paper className={classes.paperAll}>
    <Link
      to={link}
      className={classes.link}
    >
      <div className={classes.divTitle}>
        {title}
      </div>
      <div>
        <img src={icon} height={90} width={90} style={{ marginBottom: 0 }} alt={title} />
      </div>
      <div className={classes.divDescription}>
        {description}
      </div>
      <div>
        <InterestedButton link={link} buttonText={"Érdekel"} />
      </div>
    </Link>
  </Paper>
}

export default ServiceCard
