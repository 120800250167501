import * as React from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import TwoColumnTitle from "../two-column-info/two-column-title"
import TwoColumnText from "../two-column-info/two-column-text"
import TwoColumnInfo from "../two-column-info/two-column-info"

const useStyles = makeStyles((theme) => ({
    divAll: {
      height: "auto",
      width: "100%",
      paddingTop: 30,
      paddingBottom: 30,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat"
    },
    imageTitleBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(415)]: {
        flexDirection: "column",
        justifyContent: "center"
      }
    },
    image: {
      marginRight: 20,
      marginBottom: 0,
      [theme.breakpoints.down(415)]: {
        marginRight: 0
      }
    },
    imageTitleBox2: {
      display: "flex",
      alignItems: "center",
      justifyContent: 'flex-end',
      [theme.breakpoints.down(415)]: {
        flexDirection: "column-reverse",
        justifyContent: "center"
      }
    },
    image2: {
      marginLeft: 20,
      marginBottom: 0,
      [theme.breakpoints.down(415)]: {
        marginLeft: 0
      }
    }
  }
))

const HomeInfoBlockThree = () => {
  const classes = useStyles()
  return <Box className={classes.divAll}>
    <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <TwoColumnInfo
        leftColContent={<Box className={classes.imageTitleBox}>
          <img src={"/images/phone.svg"} height={80} width={80} className={classes.image} alt={"app"} />
          <TwoColumnTitle firstPart={"Applikáció"}
                          secondPart={" Fejlesztés"}
                          isSecondPartPrimaryColor color={"#fff"} />
        </Box>}
        rightColContent={<TwoColumnText
          content={<span>
            Jól megtervezett mobilalkalmazásokat készítünk
            iOS és Android rendszerre egyaránt a széleskörűbb közönség eléréséhez.
            <br />
            <br />
          A bevált módszerek a lehető legjobb élményt garantálják a felhasználók számára a fejlesztés minden szakaszában.
          </span>}
          color={"#fff"} />}
        backgroundColor={"rgba(200, 200, 200, 0.2)"}
        backdropFilter={"blur(40px)"}

      />
      <Box sx={{ height: "40px" }} />
      <TwoColumnInfo
        rightColContent={<Box className={classes.imageTitleBox2}>
          <TwoColumnTitle firstPart={"Támogatás"}
                          secondPart={" & Menedzsment"}
                          rightAlign
                          color={"#fff"}
          />
          <img src={"/images/bug.png"} height={80} width={80} className={classes.image2} alt={"app"} />
        </Box>}
        leftColContent={<TwoColumnText
          content={<span>Lehet, hogy a projekt befejeződik, de a mi munkánk nem ér véget. Folyamatos minőséget biztosítunk, és támogatjuk a fokozatos fejlesztéseket.
            <br />
            <br />
        A sikeres projekt kidolgozása a folyamatosan változó piaci igények kielégítését jelenti. Azért vagyunk itt, hogy segítsük az Ön növekedését.</span>}
          color={"#fff"} />}
        reverseWrap
        backgroundColor={"rgba(200, 200, 200, 0.2)"}
      />
    </Container>
  </Box>
}

export default HomeInfoBlockThree
